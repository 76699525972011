import React from 'react';
import Button from './../../components/buttons/';
import style from './cta.module.scss';

const CTA = ({ label, link }) => {
    return (
        <div className={style.cta}>
            <Button primary cta type="AHREF" to={link}>{label}</Button>
        </div>
    );
};

export default CTA;
