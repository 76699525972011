import React from 'react';
import { graphql } from 'gatsby';
import {LanguageLayout} from '../containers/layouts';
import ArticleHeader from './../containers/articleHeader';
import CTA from "./../containers/cta";
import Content from './../components/content/';
import Wrapper from './../components/wrapper/';
import WrapperContent from './../components/wrapperContent/';
import QuickContact from "../components/quickContact";
import SocialBar from './../components/socialBar';
import InterestCarrousel from './../containers/interestCarrousel';

export const ExpertiseDetailTemplate = ({ frontmatter, html, quickContact, slug }) => (
    <article>
        <ArticleHeader title={frontmatter.title} />
        <Wrapper>
            <WrapperContent>
                {frontmatter.ctaLabel && frontmatter.ctaAction &&
                    <CTA label={frontmatter.ctaLabel} link={frontmatter.ctaAction} />
                }
                <Content html={html} />
                <SocialBar slug={slug} messageID="socialBar.expertise.socialMessage" pageTitle={frontmatter.title} noBorder email />
            </WrapperContent>
        </Wrapper>
        {quickContact &&
        <Wrapper>
            <WrapperContent component color="blue">
                <QuickContact
                    title={quickContact.quickContactTitle}
                    text={quickContact.quickContactText}
                    phone={quickContact.quickContactPhone}
                    email={quickContact.quickContactEmail}
                    image={quickContact.quickContactImage}
                />
            </WrapperContent>
        </Wrapper>
        }
        <InterestCarrousel highlights={[frontmatter.title]} />
    </article>
);

const ExpertisePage = (props) => {
    let quickContact = null;
    const { frontmatter, html, fields } = props.data.page.edges[0].node;
    const { quickContacts } = props.data;

    if (quickContacts && quickContacts.group ){
        quickContacts.group.map(({nodes}) => {
            const result = nodes.filter((gnodes) => gnodes.frontmatter.quickContactName === frontmatter.quickContact);
            if (result.length > 0) {
                quickContact = result[0].frontmatter;
            }
            return nodes;
        });
    }

    if (props.data.page.edges.length > 0) {
        return (
          <LanguageLayout location={props.location}>
              <ExpertiseDetailTemplate
              frontmatter={frontmatter}
              html={html}
              quickContact={quickContact}
              slug={fields.slug}
              />
          </LanguageLayout>
        );
    }

    return (
        <div>Page not found</div>
    );
};

export default ExpertisePage;

export const expertiseDetailQuery = graphql`
    query ExpertiseBySlug($slug: String!) {
        page: allMarkdownRemark(filter: { frontmatter: {templateKey: {eq: "expertise-page"}}, fields: {slug: {eq: $slug}}}) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        quickContact
                        ctaLabel
                        ctaAction
                    }
                    fields {
                        slug
                    }
                    html
                }
            }
        }
        quickContacts: allMarkdownRemark {
            group(field: frontmatter___quickContactName) {
                nodes {
                    frontmatter {
                        quickContactEmail
                        quickContactPhone
                        quickContactText
                        quickContactTitle
                        quickContactName
                        quickContactImage {
                            publicURL
                            childImageSharp {
                                fixed(width: 128, height: 128) {
                                    ...GatsbyImageSharpFixed_noBase64
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
